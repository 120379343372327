/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { shape, string, bool } from 'prop-types';
import Markdown from '@/components/Markdown';
import { Grid, Typography, TextField } from '@mui/material';
import SalesforceForm from './SalesforceForm';
import SalesforceSelect from './SalesforceSelect';
import SalesforceInput from './SalesforceInput';

const SalesforceFormBlockV2 = ({ theme: cmsTheme, formName, heading, subheading, formHtml, isAlignedRight }) => {
  const [dateInput, setDateInput] = useState('');

  let label = '';

  const components = {
    br: () => null,
    label: ({ children }) => {
      label = children;
      return null;
    },
    form: props => <SalesforceForm formName={formName} dateInput={dateInput} {...props} />,
    select: props => <SalesforceSelect label={label} {...props} />,
    input: props => {
      const isDate = label?.toLowerCase().includes('date');

      useEffect(() => {
        if (isDate) {
          setDateInput(props.name);
        }
      }, []);

      const currLabel = label;
      label = '';

      return <SalesforceInput label={currLabel} {...props} />;
    },
    textarea: props => (
      <TextField
        placeholder={label}
        {...props}
        sx={{
          height: 55,
          width: '100%',
          input: {
            fontSize: '1.25rem',
          },
        }}
      />
    ),
  };

  return (
    <Grid container flexDirection={isAlignedRight ? 'row-reverse' : 'row'} alignItems="center" spacing={10}>
      <Grid item xs={12} md={6}>
        <Markdown source={formHtml} theme={cmsTheme} components={components} />
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="h2">{heading}</Typography>
        <Typography variant="body1">{subheading}</Typography>
      </Grid>
    </Grid>
  );
};

SalesforceFormBlockV2.propTypes = {
  theme: shape({}).isRequired,
  formName: string.isRequired,
  heading: string.isRequired,
  subheading: string.isRequired,
  /**
   * Generate form HTML in SF (can be either a web-to-lead or web-to-case form).
   * Make sure all label text is wrapped in a [label tag](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/label).
   * For hidden fields, such as lead type, use a [hidden input](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/hidden).
   */
  formHtml: shape({ compiledSource: string, scope: shape({}) }).isRequired,
  isAlignedRight: bool.isRequired,
};

export default SalesforceFormBlockV2;
