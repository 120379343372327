import React from 'react';
import { shape } from 'prop-types';
import { Box, Typography } from '@mui/material';

const ZipCode = ({ cityData }) => (
  <Box display="flex" alignItems="center" flexDirection="column" width="100%">
    <Box width={{ xs: '100%', md: '80%' }} display="flex" align="center">
      {cityData?.zipCode?.map(zip => (
        <Box
          display="flex"
          flexDirection="column"
          width={{ xs: '100%', sm: 'calc(100% / 3)', lg: '20%' }}
          // Disabling eslint rule as docs state to not use rule when
          // breaking array into chunks which is what we're doing here
          // eslint-disable-next-line react/no-array-index-key
          key={zip}
        >
          <Typography variant="caption" align="center">
            {zip}
          </Typography>
        </Box>
      ))}
    </Box>
  </Box>
);

ZipCode.propTypes = {
  cityData: shape({}).isRequired,
};

export default ZipCode;
