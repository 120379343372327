import React, { useState } from 'react';
import { arrayOf, shape, string } from 'prop-types';
import { Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Link from '../../Link';

const StateList = ({ states, stateTypes }) => {
  const stateSections = stateTypes?.length ? stateTypes : [{ id: 'guides', label: 'Guides', slug: 'moving-to' }];
  const isTabbed = stateSections.length > 1;
  const theme = useTheme();

  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const tablet = useMediaQuery(theme.breakpoints.down('md'));

  const mobileCols = 1;
  const tabletCols = 3;
  const desktopCols = 5;

  const [currentTab, setCurrentTab] = useState(isTabbed ? stateTypes[0].id : '');

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  // Divides total number of states by desired number of columns to determine number
  // of states in each column. This is required to prevent reflow of one state to the
  // next column that would occur in a standard flex layout.
  const origStatesNum = Object.keys(states).length;
  let statesPerColumn = Math.ceil(origStatesNum / desktopCols);
  const flooredStatesPerColumn = Math.floor(origStatesNum / desktopCols);
  const statesPerColumnModulo = origStatesNum % desktopCols;
  if (tablet) statesPerColumn = Math.ceil(origStatesNum / tabletCols);
  if (mobile) statesPerColumn = origStatesNum;
  let stateColumns = desktopCols;
  if (tablet) stateColumns = tabletCols;
  if (mobile) stateColumns = mobileCols;

  const stateComponent = state => (
    <Box>
      {stateSections.slice(0, 2).map(({ id, serviceName: path }) => (
        <Link
          gutterBottom
          variant="body2"
          href={`/${state.slug}/${path}`}
          key={`link-${id}`}
          underline="none"
          display={!isTabbed || currentTab === id ? 'block' : 'none'}
        >
          {state.state}
        </Link>
      ))}
    </Box>
  );

  return (
    <Box display="flex" alignItems="center" flexDirection="column" width="100%">
      {isTabbed && (
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          variant={mobile ? 'fullWidth' : 'standard'}
          sx={{
            '& .MuiTab-root': {
              textTransform: 'none',
              fontSize: { xs: 20, md: 36 },
            },
            mb: 4,
          }}
        >
          {stateSections.slice(0, 2).map(({ id, title }) => (
            <Tab value={id} label={title} />
          ))}
        </Tabs>
      )}
      <Box width={{ xs: '100%', md: '85%' }} display="flex" align="center">
        {[...Array(stateColumns)].map((_, i) => (
          <Box
            display="flex"
            flexDirection="column"
            width={{ xs: '100%', sm: 'calc(100% / 3)', lg: '20%' }}
            // Disabling eslint rule as docs state to not use rule when
            // breaking array into chunks which is what we're doing here
            // eslint-disable-next-line react/no-array-index-key
            key={i}
          >
            {(states && i < statesPerColumnModulo) || !statesPerColumnModulo
              ? states?.slice(i * statesPerColumn, (i + 1) * statesPerColumn).map(state => stateComponent(state))
              : states
                  ?.slice((i + 1) * flooredStatesPerColumn, (i + 2) * flooredStatesPerColumn)
                  .map(state => stateComponent(state))}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

StateList.propTypes = {
  states: arrayOf(
    shape({
      state: string.isRequired,
      slug: string.isRequired,
    }),
  ).isRequired,
  stateTypes: arrayOf(
    shape({
      id: string.isRequired,
      title: string.isRequired,
      serviceName: string.isRequired,
    }),
  ).isRequired,
};

export default StateList;
