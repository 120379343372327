import React from 'react';
import { string, shape } from 'prop-types';
import { Card as MuiCard, useTheme, Box, CardContent, Typography } from '@mui/material';
import { LINK_TYPE } from '@/types';
import CardWrapper from '@/components/CardWrapper';

const Card = ({
  header,
  title,
  icon,
  content,
  titleComponent,
  titleVariant,
  contentComponent,
  contentVariant,
  link,
  align,
}) => {
  const theme = useTheme();
  const isCentered = align !== 'left';
  const isOnlyTitleAndContent = !header && !icon && !link;

  return (
    <CardWrapper link={link}>
      <MuiCard
        sx={{
          height: '100%',
          border: `1px solid ${theme.palette.neutral.light}`,
          borderRadius: '7px',
          boxShadow: 'none',
          ...(link && {
            '&:hover': {
              background: theme.palette.neutral.lighter,
              transition: 'background linear 0.2s',
              cursor: 'pointer',
            },
          }),
        }}
      >
        <CardContent>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems={isCentered ? 'center' : 'flex-start'}
          >
            <Typography
              align={isCentered ? 'center' : 'left'}
              variant="body2"
              gutterBottom
              sx={{
                color: theme.palette.neutral.medium,
                textTransform: 'uppercase',
              }}
            >
              {header}
            </Typography>
            {icon && (
              <Box maxWidth="100%">
                <img src={icon.url} alt={icon.alt} loading="lazy" />
              </Box>
            )}
            <Typography
              align={isCentered ? 'center' : 'left'}
              component={titleComponent || 'h3'}
              variant={titleVariant || 'h4'}
              gutterBottom
              color={isOnlyTitleAndContent ? theme.palette.secondary.main : theme.palette.text.primary}
            >
              {title}
            </Typography>
            {content && (
              <Typography
                align={isCentered ? 'center' : 'left'}
                component={contentComponent || 'h4'}
                color={theme.palette.text.secondary}
                variant={contentVariant || 'h4'}
                gutterBottom={!!link}
              >
                {content}
              </Typography>
            )}
            {link && (
              <Typography color="primary" variant="body2" align={isCentered ? 'center' : 'left'}>
                {link.label}
              </Typography>
            )}
          </Box>
        </CardContent>
      </MuiCard>
    </CardWrapper>
  );
};

Card.propTypes = {
  header: string,
  title: string.isRequired,
  titleComponent: string,
  titleVariant: string,
  icon: shape({
    url: string,
  }),
  content: string,
  contentComponent: string,
  contentVariant: string,
  link: LINK_TYPE,
  align: string,
};

Card.defaultProps = {
  header: '',
  icon: null,
  titleComponent: '',
  titleVariant: '',
  content: '',
  contentComponent: '',
  contentVariant: '',
  link: null,
  align: 'center',
};

export default Card;
