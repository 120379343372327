import React from 'react';
import { Breadcrumbs, Typography } from '@mui/material';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { useRouter } from 'next/router';
import { shape } from 'prop-types';

import BreadcrumbLink from '../../BreadcrumbLink';

const Breadcrumb = ({ cityData, neighborhoodData }) => {
  const { asPath } = useRouter();
  const pathArr = asPath.split('/');
  const crumbs = () => {
    if (neighborhoodData) {
      return (
        <Breadcrumbs separator={<DoubleArrowIcon fontSize="small" />} aria-label="breadcrumb">
          <BreadcrumbLink link={`${pathArr[1]}/${pathArr[4]}`} location={`${neighborhoodData.cityV2.state.state}`} />
          <BreadcrumbLink link={`${pathArr[1]}/${pathArr[2]}/${pathArr[4]}`} location={`${neighborhoodData.cityV2.city}`} />
          <BreadcrumbLink
            link={`${pathArr[1]}/${pathArr[2]}/${pathArr[3]}/${pathArr[4]}`}
            location={`${neighborhoodData.neighborhood}`}
          />
          <Typography color="text.primary" variant="body2">
            {neighborhoodData.neighborhoodGenPages[0].serviceName}
          </Typography>
        </Breadcrumbs>
      );
    }

    if (cityData) {
      return (
        <Breadcrumbs separator={<DoubleArrowIcon fontSize="small" />} aria-label="breadcrumb">
          <BreadcrumbLink link={`${pathArr[1]}/${pathArr[3]}`} location={`${cityData.state.state}`} />
          <BreadcrumbLink link={`${pathArr[1]}/${pathArr[2]}/${pathArr[3]}`} location={`${cityData.city}`} />
          <Typography color="text.primary" variant="body2">
            {cityData.cityGenPagesV2[0].serviceName}
          </Typography>
        </Breadcrumbs>
      );
    }

    return null;
  };

  return crumbs();
};

Breadcrumb.propTypes = {
  stateData: shape({}),
  cityData: shape({}).isRequired,
  neighborhoodData: shape({}),
};

export default Breadcrumb;
