import React from 'react';
import { Typography } from '@mui/material';
import { string } from 'prop-types';
import Link from '../Link';

const BreadcrumbLink = ({ link, location }) => (
  <Link href={location === 'Home' ? `/` : `/${link}/`}>
    <Typography variant="body2">{location}</Typography>
  </Link>
);

BreadcrumbLink.propTypes = {
  link: string.isRequired,
  location: string.isRequired,
};

export default BreadcrumbLink;
