/* eslint-disable no-param-reassign */
/* eslint-disable import/prefer-default-export */
import fetchBellhopAPI from '@/utils/bellhop/fetchApi';
import { GET_LOCATION } from '../gql';

export async function getLocation(address) {
  const data = await fetchBellhopAPI(GET_LOCATION, {
    variables: {
      address,
    },
  });
  return data;
}
