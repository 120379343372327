import React from 'react';
import { string, shape } from 'prop-types';

import { Typography, Box } from '@mui/material';
import Markdown from '@/components/Markdown';

const GmbMapBlock = ({ pb, title, headline, body, alignMap, theme }) => (
  <Box display="flex" justifyContent={alignMap} width="100%">
    <Box
      order={alignMap === 'right' ? 1 : 2}
      flexDirection="column"
      sx={{ px: 4, pt: 1, display: { xs: 'none', md: 'flex' } }}
      maxWidth={600}
      mx="auto"
    >
      <Typography variant="h2">{headline}</Typography>

      <Markdown source={body} theme={theme} />
    </Box>
    <Box order={alignMap === 'right' ? 2 : 1} flex="1">
      <iframe
        src={`https://www.google.com/maps/embed?pb=${pb}`}
        width="100%"
        height="450"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        title={title}
      />
    </Box>
  </Box>
);

GmbMapBlock.propTypes = {
  pb: string.isRequired,
  title: string.isRequired,
  headline: string.isRequired,
  alignMap: string.isRequired,
  body: shape({}).isRequired,
  theme: shape({}).isRequired,
};

export default GmbMapBlock;
