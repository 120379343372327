import React from 'react';
import { Grid } from '@mui/material';
import { arrayOf, shape, string, number } from 'prop-types';

import { LINK_TYPE } from '@/types';
import Card from './Card';

const CardsBlock = ({ cards }) => (
  <Grid container spacing={3} justifyContent="center">
    {cards.map(({ id, ...props }) => (
      <Grid item key={id} xs="12" sm="6" lg={cards.length % 2 ? 4 : 6}>
        <Card {...props} />
      </Grid>
    ))}
  </Grid>
);

CardsBlock.propTypes = {
  cards: arrayOf(
    shape({
      id: string,
      icon: shape({
        id: string,
        label: string,
        url: string,
        alt: string,
        handle: string,
        width: number,
        height: number,
      }),
      title: string.isRequired,
      content: string,
      link: LINK_TYPE,
    }),
  ).isRequired,
};

export default CardsBlock;
