import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
import Markdown from '@/components/Markdown';
import { shape, string } from 'prop-types';

const CityFooterBlock = ({ mainContent, locationInformation, support, dividerContent, theme }) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
    }}
  >
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        pb: { xs: 4, md: 2 },
        pl: { xs: 5, md: 0 },
      }}
    >
      <Box maxWidth={{ md: 400, lg: 500 }} pl={{ xs: 0, md: 3 }} mt={-1} pb={{ xs: 3, md: 0 }}>
        <Markdown source={mainContent} theme={theme} />
      </Box>
      <Box maxWidth={320} px={{ md: 2, lg: 0 }} pb={{ xs: 5, md: 0 }}>
        <Markdown
          source={locationInformation}
          theme={theme}
          components={{
            p: props => <Typography variant="body2" color={theme.bodyColor} sx={{ mb: 0 }} {...props} />,
          }}
        />
      </Box>
      <Box maxWidth={320} pl={{ md: 5, lg: 8 }}>
        <Markdown
          source={support}
          theme={theme}
          components={{
            p: props => <Typography variant="body2" color={theme.bodyColor} sx={{ mb: 0 }} {...props} />,
          }}
        />
      </Box>
    </Box>
    <Divider variant="fullWidth" />
    <Box pl={{ xs: 5, md: 3 }} pr={{ xs: 3, md: 40 }} pt={3}>
      <Markdown source={dividerContent} theme={theme} />
    </Box>
  </Box>
);

CityFooterBlock.propTypes = {
  /** The main content piece referencing the city on the left (top on mobile) */
  mainContent: shape({
    compiledSource: string.isRequired,
  }).isRequired,
  /** The location info (address, phone number) */
  locationInformation: shape({
    compiledSource: string.isRequired,
  }).isRequired,
  /** Bellhop support info */
  support: shape({
    compiledSource: string.isRequired,
  }).isRequired,
  /** Content below the divider */
  dividerContent: shape({
    compiledSource: string.isRequired,
  }).isRequired,
  /** Block theme */
  theme: shape({
    backgroundColor: string.isRequired,
    titleColor: string.isRequired,
    bodyColor: string.isRequired,
    iconColor: string.isRequired,
  }).isRequired,
};

export default CityFooterBlock;
