import React, { useState } from 'react';

import { useRouter } from 'next/router';
import { arrayOf, shape, string } from 'prop-types';

import { Box, Card } from '@mui/material';

import createLead from '../../../../../../common/util/partnerApi';
import { identify, track } from '../../../../modules/segment';
import Form from './Form';
import Thanks from './Thanks';

const FormCard = ({
  formName,
  title,
  inputs,
  buttonText,
  submittedTitle,
  submittedDescription,
  submittedButtonText,
  leadType,
  leadRecordType,
  leadSource,
}) => {
  const { query } = useRouter();

  const [customerLeads, setCustomerLead] = useState(
    // GraphCMS picklists do not allow spaces so we use an underscore then replace them with a space here.
    {
      lead_record_type: leadRecordType?.replace('_', ' ') || '',
      lead_type: leadType?.replace('_', ' ') || '',
      lead_source: leadSource?.replace('_', ' ') || '',
    },
  );

  const [submitted, setSubmitted] = useState(false);

  const onSubmit = () => {
    track(`Form Submitted - ${formName}`, {
      email: customerLeads.email,
      phone: customerLeads.phone,
    });

    identify(customerLeads);

    const { utm_medium: utmMedium, utm_source: utmSource, utm_campaign: utmCampaign, clickid, gclid, msclkid } = query;

    createLead({
      ...customerLeads,
      utm_medium: utmMedium,
      utm_source: utmSource,
      utm_campaign: utmCampaign,
      click_id: clickid,
      gclid,
      msclkid,
    });
    setSubmitted(true);
  };

  const onChange = (event, apiInputName) => {
    let { value } = event.target;

    // Formats date if value is a date type
    const dateValue = new Date(value);
    if (value.match(/-/g)?.length === 2 && dateValue !== 'Invalid Date') {
      [value] = dateValue.toISOString().split('T');
    }

    setCustomerLead({
      ...customerLeads,
      [apiInputName]: value,
    });
  };

  return (
    <Box
      sx={{
        width: {
          xs: '100%',
          md: 500,
        },
      }}
    >
      <Card
        raised
        sx={{
          width: {
            xs: '100%',
            md: 500,
          },
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          px: { xs: 3, md: 2 },
        }}
      >
        {!submitted ? (
          <Form title={title} inputs={inputs} onChange={onChange} buttonText={buttonText} onSubmit={onSubmit} />
        ) : (
          <Thanks title={submittedTitle} description={submittedDescription} buttonText={submittedButtonText} />
        )}
      </Card>
    </Box>
  );
};

FormCard.propTypes = {
  // Used for tracking form submissions
  formName: string.isRequired,

  /** The title of the form pre-submission. */
  title: string.isRequired,
  /** The values for a given input field. One object is one input field.
   */
  inputs: arrayOf(
    shape({
      apiInputName: string,
      /** The placeholder value in the input field. */
      label: string,
      /** See See https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input for possible input types. */
      type: string,
      required: string,
      defaultValue: string,
      display: string,
    }),
  ).isRequired,
  /** The text of the form button pre-submission */
  buttonText: string.isRequired,

  /** The title of the form post-submission. */
  submittedTitle: string.isRequired,
  /** The description post-submission. */
  submittedDescription: string.isRequired,
  /** The text of the form button post-submission */
  submittedButtonText: string.isRequired,

  /** The lead record type for a lead form. */
  leadType: string.isRequired,
  /** The lead type for a lead form. */
  leadRecordType: string.isRequired,
  /** The lead record source for a lead form. */
  leadSource: string.isRequired,
};

export default FormCard;
