import React from 'react';
import { bool, shape, string } from 'prop-types';
import Markdown from '@/components/Markdown';
import { Box } from '@mui/material';

const FancyTextBlock = ({ theme, content: { source }, center, components }) => (
  <Box display={center ? 'flex' : 'block'} justifyContent={center ? 'center' : null}>
    <Markdown source={source} theme={theme} components={components} />
  </Box>
);

FancyTextBlock.propTypes = {
  content: shape({
    source: shape({ compiledSource: string }),
  }).isRequired,
  theme: shape({
    titleColor: string.isRequired,
    bodyColor: string.isRequired,
  }).isRequired,
  center: bool,
  components: shape({}),
};

FancyTextBlock.defaultProps = {
  center: false,
  components: null,
};

export default FancyTextBlock;
