import React, { useState } from 'react';
import { string, number, shape, oneOfType } from 'prop-types';
import { Box, Typography, Rating, Card, Modal, Divider } from '@mui/material';
import { Close } from '@mui/icons-material';
import Button from '@/components/Button';
import QuoteBody from './QuoteBody';

const QuoteBlock = ({ quote, author, rating, quoteGroup, theme: { bodyColor, backgroundColor } }) => {
  const count = quoteGroup.length;
  // Sort to newest first
  quoteGroup.sort((a, b) => Date.parse(new Date(b.date)) - Date.parse(new Date(a.date)));
  const avg = quoteGroup.reduce((a, c) => a + c.rating, 0) / count;
  const [modalOpen, setModalOpenState] = useState(false);

  return (
    <Box>
      {quoteGroup.length > 0 && (
        <Modal
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          open={modalOpen}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              width: '90vw',
              maxWidth: '650px',
              background: 'white',
              borderRadius: 1,
              ':focus-visible': {
                outline: 'none',
              },
              display: 'flex',
              padding: 1,
              flexDirection: 'column',
            }}
          >
            <Box sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
              <Close
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  setModalOpenState(false);
                }}
              />
            </Box>
            <Box sx={{ paddingLeft: { xs: 1, sm: 4 }, paddingRight: 1 }}>
              <Box sx={{ paddingRight: { xs: 1, sm: 4 } }}>
                <Typography color="text.primary" component="h2" variant="h2" mb={1}>
                  Bellhop Moving
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: { xs: 'flex-start', sm: 'flex-end' },
                    justifyContent: 'space-between',
                    flexDirection: { xs: 'column', sm: 'row' },
                  }}
                >
                  <Box sx={{ display: 'flex', gap: { xs: 0, sm: 2 }, flexDirection: { xs: 'column', sm: 'row' } }}>
                    <Rating sx={{ color: '#6994F4' }} value={Number(avg)} precision={0.5} readOnly />
                    <Typography
                      color="textSecondary"
                      component="p"
                      variant="body2"
                      sx={{ lineHeight: { xs: '1.2em', sm: 'initial' } }}
                    >
                      {avg} Avg - {count - 1}+ reviews
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', gap: '3px' }}>
                    <Typography color="textSecondary" component="p" sx={{ fontSize: '12px !important' }}>
                      Sorted by
                    </Typography>
                    <Typography color="text.primary" component="p" sx={{ fontSize: '12px !important', fontWeight: 'bold' }}>
                      Newest
                    </Typography>
                  </Box>
                </Box>
                <Divider sx={{ paddingY: 1 }} />
              </Box>
              <Box
                sx={{
                  paddingTop: 2,
                  paddingRight: { xs: 1, sm: 4 },
                  maxHeight: '75vh',
                  overflow: 'auto',
                  '::-webkit-scrollbar': {
                    width: '5px',
                  },
                  '::-webkit-scrollbar-track': {
                    boxShadow: 'inset 0 0 5px white',
                    borderRadius: '10px',
                  },
                  '::-webkit-scrollbar-thumb': {
                    background: '#B6B8BB',
                    borderRadius: '10px',
                  },
                }}
              >
                {quoteGroup.map((q, i) => (
                  <>
                    <QuoteBody sx={{ padding: 0 }} quote={q.quote} author={q.author} rating={q.rating} date={q.date} />
                    {i < quoteGroup.length - 1 && <Divider sx={{ paddingY: 1, marginBottom: 3 }} />}
                  </>
                ))}
              </Box>
            </Box>
          </Box>
        </Modal>
      )}
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        textAlign="center"
        backgroundColor={backgroundColor}
        pt={{
          xs: 9,
          md: 12,
        }}
        pb={{
          xs: 9,
          md: 12,
        }}
      >
        <Box pb={1}>
          <img height={40} width={60} src="/images/quote.svg" alt="quotes" loading="lazy" />
        </Box>
        <Box maxWidth={750}>
          <Typography variant="body1" color={bodyColor}>
            {quote}
          </Typography>
        </Box>

        <Box pt={2}>
          <Typography variant="body2" color="textSecondary">
            {`— ${author}`}
          </Typography>
        </Box>

        <Box mt={2}>
          <Rating value={Number(rating)} readOnly />
        </Box>
      </Box>
      {quoteGroup.length > 0 && (
        <Box
          sx={{ position: 'relative', top: { md: '-50px' }, display: 'flex', flexDirection: 'column', alignItems: 'center' }}
        >
          <Box
            sx={{
              flex: 1,
              display: { xs: 'none', md: 'flex' },
              justifyContent: 'space-between',
              gap: 2,
              marginX: { xs: 2, md: 7 },
              maxWidth: '1200px',
              flexDirection: 'row',
            }}
          >
            {quoteGroup.slice(0, 3).map(q => (
              <Card sx={{ flex: 1, height: '200px' }}>
                <QuoteBody expandable={false} quote={q.quote} author={q.author} rating={q.rating} date={q.date} />
              </Card>
            ))}
          </Box>
          <Button
            variant="outlined"
            sx={{ mt: { xs: 4, md: 5 }, mb: 2.5, width: 250 }}
            onClick={() => {
              setModalOpenState(true);
            }}
          >
            See more reviews
          </Button>
        </Box>
      )}
    </Box>
  );
};

QuoteBlock.propTypes = {
  quote: string.isRequired,
  author: string.isRequired,
  rating: oneOfType([number, string]).isRequired,
  theme: shape({
    titleColor: string.isRequired,
  }).isRequired,
  quoteGroup: shape({
    author: string.isRequired,
    date: string.isRequired,
    rating: number.isRequired,
    quote: string.isRequired,
  }).isRequired,
};

export default QuoteBlock;
