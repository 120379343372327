import React from 'react';
import dynamic from 'next/dynamic';
import { func, string } from 'prop-types';
import { Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
// import ReactPlayer from 'react-player';

const ReactPlayer = dynamic(() => import('react-player'));

const VideoStage = ({ setVideoStageOpen, videoUrl }) => (
  <Box
    onClick={() => {
      setVideoStageOpen(false);
    }}
    position="fixed"
    top={0}
    right={0}
    bottom={0}
    left={0}
    zIndex={10000}
    display="flex"
    justifyContent="center"
    alignItems="center"
  >
    <Box /* Transparent black background mask */
      position="absolute"
      width="100%"
      height="100%"
      sx={{ background: 'black', opacity: '0.75' }}
    />
    <Box /* Close Icon */
      onClick={() => {
        setVideoStageOpen(false);
      }}
      display="flex"
      position="absolute"
      top={20}
      right={20}
      sx={{
        color: 'neutral.lightest',
        '&:hover': {
          cursor: 'pointer',
        },
      }}
    >
      <CloseIcon color="inherit" />
    </Box>
    <Box /* Video container */
      maxWidth={1366}
      maxHeight={768}
      width={{
        xs: '100%',
        md: '80%',
      }}
      height="calc(9 / 16 * 100vw)" /* locks Box to 16:9 ratio */
      position="absolute"
    >
      <ReactPlayer
        url={`${videoUrl}`}
        width="100%"
        height="100%"
        config={{
          youtube: {
            playerVars: { autoplay: 1 },
          },
        }}
        controls
      />
    </Box>
  </Box>
);

VideoStage.propTypes = {
  setVideoStageOpen: func.isRequired,
  videoUrl: string.isRequired,
};

export default VideoStage;
