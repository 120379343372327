import React from 'react';
import { arrayOf, string, shape } from 'prop-types';
import { Box, Typography } from '@mui/material';

const FastFactBlock = ({ facts, theme: { bodyColor } }) => (
  <Box display="flex" justifyContent="space-evenly" flexWrap="wrap">
    {facts.map(({ title, metric }, i) => (
      <Box
        key={title}
        width={{ xs: 'auto', md: 1 / facts.length }}
        px={3}
        mb={{ xs: i + 1 !== facts.length ? 3 : 0, md: 0 }}
      >
        <Typography variant="h1" component="p" fontFamily="body1.fontFamily" color="secondary.main" align="center">
          {metric}
        </Typography>
        <Typography variant="body1" color={bodyColor} align="center">
          {title}
        </Typography>
      </Box>
    ))}
  </Box>
);

FastFactBlock.propTypes = {
  facts: arrayOf(
    shape({
      title: string.isRequired,
      metric: string.isRequired,
    }),
  ).isRequired,
  theme: shape({
    bodyColor: string.isRequired,
  }).isRequired,
};

export default FastFactBlock;
