export default async function fetchSalesforceAPI(data, sfEndpoint) {
  const params = new URLSearchParams();
  Object.keys(data).forEach(key => {
    params.append(key, data[key]);
  });
  await fetch(sfEndpoint, {
    mode: 'no-cors',
    method: 'POST',
    body: params,
  });
}
