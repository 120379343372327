import React, { useState } from 'react';
import { useRouter } from 'next/router';
import { shape, string, arrayOf } from 'prop-types';
import { Box, Card } from '@mui/material';

import fetchSalesforceAPI from '@/utils/salesforce/fetchApi';
import { identify, track } from '../../../../modules/segment';
import Form from './Form';
import Thanks from './Thanks';

const FormCard = ({
  formName,
  formType,
  inputs,
  title,
  buttonText,
  submittedButtonText,
  submittedTitle,
  submittedDescription,
  leadType,
  leadRecordType,
  leadSource,
}) => {
  const router = useRouter();

  const leadRecordTypes = {
    Ambassador: '0124p000000iFa9',
    B2B_Partnership: '0124p000000iFb7',
    Brokerage: '01246000000hqoi',
    Carrier: '01246000000y5ZN',
    Long_Distance: '01246000000hs1R',
    Partner: '012460000015RSv',
    Potential_Customer: '0124p000000V5K2',
    Storage: '0124p000000yCtX',
  };

  const [values, setValues] = useState(
    // GraphCMS picklists do not allow spaces so we use an underscore then replace them with a space here.
    formType === 'case'
      ? {
          '00N4p00000FlZ04': 'Other',
        }
      : {
          lead_source: leadSource,
          recordType: leadRecordTypes[leadRecordType] || '',
          '00N4p00000EdmPO': leadType?.replace('_', ' ') || '',
        },
  );
  const [submitted, setSubmitted] = useState(false);

  const onSubmit = () => {
    track(`Form Submitted - ${formName}`, {
      email: values.email,
      phone: values.phone,
    });

    identify(values);

    setSubmitted(true);

    if (process.env.NEXT_PUBLIC_ENVIRONMENT === 'production') {
      fetchSalesforceAPI(
        {
          [formType === 'lead' ? 'oid' : 'orgid']: process.env.NEXT_PUBLIC_SALESFORCE_ORG_ID,
          submit: 'Submit',
          ...(router.query.clickid && { Clickid__c: router.query.clickid }),
          ...(router.query.gclid && { Gclid__c: router.query.gclid }),
          ...values,
        },
        formType === 'lead'
          ? 'https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8'
          : 'https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8',
      );
    }
  };

  const onChange = (event, apiInputName) => {
    let { value } = event.target;
    const dateValue = new Date(value);
    // Check if date value, then format to proper SF expected format (mm/dd/yyyy)
    if (value.match(/-/g)?.length === 2 && dateValue !== 'Invalid Date') {
      value = dateValue.toLocaleDateString();
    }
    setValues({
      ...values,
      [apiInputName.replace('SF_', '')]: value,
    });
  };

  return (
    <Box
      sx={{
        width: {
          xs: '100%',
          md: 500,
        },
      }}
    >
      <Card
        raised
        sx={{
          width: {
            xs: '100%',
            md: 500,
          },
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          px: { xs: 3, md: 2 },
        }}
      >
        {!submitted ? (
          <Form
            formType={formType}
            title={title}
            inputs={inputs}
            onChange={onChange}
            onSubmit={onSubmit}
            buttonText={buttonText}
          />
        ) : (
          <Thanks title={submittedTitle} description={submittedDescription} buttonText={submittedButtonText} />
        )}
      </Card>
    </Box>
  );
};

FormCard.propTypes = {
  // Used for tracking form submissions
  formName: string.isRequired,
  /** The type of form ("lead" or "case") */
  formType: string.isRequired,
  /** The text of the form button pre-submission */
  buttonText: string.isRequired,
  /** The text of the form button post-submission */
  submittedButtonText: string.isRequired,
  /** The values for a given input field. One object is one input field.
   */
  inputs: arrayOf(
    shape({
      /** The name value given by the Salesforce web-to-lead or web-to-case generator. */
      apiInputName: string,
      /** The placeholder value in the input field. */
      label: string,
      /** See See https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input for possible input types. */
      type: string,
    }),
  ).isRequired,
  /** The title of the form pre-submission. */
  title: string.isRequired,
  /** The title of the form post-submission. */
  submittedTitle: string.isRequired,
  /** The description post-submission. */
  submittedDescription: string.isRequired,
  leadType: string.isRequired,
  leadSource: string.isRequired,
  leadRecordType: string.isRequired,
};

export default FormCard;
