import React from 'react';
import { string } from 'prop-types';
import { Button, OutlinedInput } from '@mui/material';

const SalesforceInput = ({ label, type, name, value, ...rest }) => {
  switch (type) {
    case 'hidden':
      return <input type={type} name={name} value={value} {...rest} />;
    case 'submit':
      return (
        <Button
          type="submit"
          variant="contained"
          sx={{ margin: theme => `${theme.spacing(2)} auto 0`, width: { xs: 280, md: 310 } }}
          {...rest}
        >
          {value}
        </Button>
      );
    default:
      return (
        <OutlinedInput
          required
          sx={{
            height: 55,
            width: '100%',
            input: {
              fontSize: '1.25rem',
            },
          }}
          placeholder={label}
          type={type}
          name={name}
          inputProps={{ ...rest, style: { WebkitBoxShadow: '0 0 0 1000px white inset' } }}
        />
      );
  }
};

SalesforceInput.propTypes = {
  label: string.isRequired,
  type: string.isRequired,
  name: string.isRequired,
  value: string,
};

SalesforceInput.defaultProps = {
  value: '',
};

export default SalesforceInput;
