import React from 'react';
import { string, shape, oneOf } from 'prop-types';
import { Card, useTheme, CardContent, CardActionArea, CardMedia, Typography } from '@mui/material';

import Markdown from '@/components/Markdown';
import { LINK_TYPE } from '@/types';
import CardWrapper from '@/components/CardWrapper';

const ImageCard = ({ title, content, image, theme, align, link }) => {
  const globalTheme = useTheme();
  return (
    <CardWrapper link={link}>
      <Card
        sx={{
          height: '100%',
          borderRadius: '7px',
          boxShadow: 'none',
          border: `1px solid ${globalTheme.palette.neutral.light}`,
          '&:hover': {
            transition: 'background linear 0.2s',
            cursor: 'pointer',
          },
        }}
      >
        <CardActionArea
          sx={{
            flexDirection: 'column',
            padding: 0,
            display: 'flex',
            alignItems: align === 'left' ? 'flex-start' : 'flex-end',
            ...(!link && {
              '&:hover': {
                cursor: 'default',
              },
            }),
          }}
        >
          <CardMedia sx={{ height: 200, width: '100%' }} image={image.url} title={title} />
          <CardContent>
            <Typography gutterBottom variant="h4" component="div" align={align}>
              {title}
            </Typography>
            <Markdown source={content} theme={theme} />
            <Typography color="primary" variant="body2" align={align}>
              {link.label}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </CardWrapper>
  );
};

ImageCard.propTypes = {
  title: string.isRequired,
  theme: shape({
    backgroundColor: string.isRequired,
    titleColor: string.isRequired,
    bodyColor: string.isRequired,
    iconColor: string.isRequired,
  }).isRequired,
  image: shape({
    url: string,
  }).isRequired,
  content: shape({
    compiledSource: string,
  }),
  link: LINK_TYPE.isRequired,
  align: oneOf(['left', 'right']).isRequired,
};

ImageCard.defaultProps = {
  content: null,
};

export default ImageCard;
