/* eslint-disable react/prop-types */
import React from 'react';
import { string, shape } from 'prop-types';
import useSWR from 'swr';
import { Box, Typography } from '@mui/material';

// Base element styles
const baseContainerStyle = {
  minHeight: 20,
  background: '#f5f7f9',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 3,
};

const weatherDataStyle = { fontFamily: 'CalibreMedium', fontSize: '48px', fontWeight: '300' };

/**
 * Format the weather type string including spacing after commas and string transformations for specific weather events
 *
 * @param {String} initial
 * @returns A Formatted String with some specific transformations for clarity
 */
const getWeatherString = initial => {
  switch (initial) {
    case 'Clouds':
      return 'Cloudy';
    case 'Drizzle,Mist':
      return 'Drizzle';
    case 'Haze,Snow':
      return 'Flurries';
    case 'Mist':
      return 'Misty';
    case 'Rain,Mist':
    case 'Mist,Rain':
      return 'Misty';
    default:
      return initial.split(',').join(', ');
  }
};

/**
 * Get the specific weather icon based on a provided weather string
 *
 * @param {String} weather
 * @returns An svg file name
 */
// Note: Removed until further notice
// const getIcon = weather => {
//   switch (weather) {
//     case 'Clouds':
//       return 'Cloudy.svg';
//     case 'Drizzle,Mist':
//       return 'Drizzle.svg';
//     case 'Haze,Snow':
//       return 'Flurries.svg';
//     case 'Mist':
//       return 'HazeMist.svg';
//     case 'Rain,Mist':
//     case 'Mist,Rain':
//       return 'RainMist.svg';
//     case 'Fog,Rain':
//     case 'Rain,Fog':
//       return 'FogRain.svg';
//     default:
//       return `${weather}.svg`;
//   }
// };

/**
 * Formats currency strings
 */
const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0, // Rounds up to nearest whole number
  maximumFractionDigits: 0,
});

const fetcher = (...args) =>
  fetch(...args)
    .then(res => res.text())
    .then(rep => {
      // Remove additional text and extract only formatted JSON
      const jsonData = JSON.parse(rep.substring(47).slice(0, -2));
      const headers = jsonData.table.cols;
      const data = jsonData.table.rows[0].c;
      let shapedData = {};
      headers.forEach((element, index) => {
        shapedData = Object.assign(shapedData, {
          [element.label]: data[index].f ?? data[index].v,
        });
      });
      return shapedData;
    });

/**
 * Create a housing market element
 *
 * @param {String} title `String`
 * @param {String | Number} value `String` or `Number`
 * @param {String} format Optional `String`: 'currency' supported
 * @returns A component for displaying housing market data
 */
const HousingMarketDetail = ({ title, value, format = '' }) => (
  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 1 }}>
    <Typography
      variant="body1"
      style={{ fontFamily: 'CalibreMedium', fontSize: '38px', fontWeight: '400', lineHeight: 1.167 }}
    >
      {format === 'currency' ? currencyFormatter.format(value) : value}
    </Typography>
    <Typography
      variant="h5"
      sx={{
        color: '#747C82',
        maxWidth: { xs: '100%', md: '230px' },
        textAlign: 'center',
        fontFamily: 'CalibreMedium',
        fontWeight: '400',
      }}
      style={{ fontSize: '18px' }}
    >
      {title}
    </Typography>
  </Box>
);

/**
 * Dynamic Local Weather Data and Housing Market Data Block
 */
const LocalWeatherBlock = ({ cityData, theme: { backgroundColor } }) => {
  const cityName = cityData.city;

  // Google Sheet query vars
  const spreadSheetId = process.env.NEXT_PUBLIC_WEATHER_ZILLOW_SHEET;
  const base = `https://docs.google.com/spreadsheets/d/${spreadSheetId}/gviz/tq?`;

  // Get Weather and Housing Data
  const hygraphSheetId = '0';
  const hygraphSheetQuery = encodeURIComponent(`SELECT * WHERE B = '${cityName}'`);
  const url1 = `${base}&gid=${hygraphSheetId}&tq=${hygraphSheetQuery}`;
  // SWR Caching request
  const { data: datum1, error: error1, isLoading: isLoading1 } = useSWR(url1, fetcher);

  // Get ACS Data
  const acsSheetId = '1124868129';
  const acsSheetQuery = encodeURIComponent(`SELECT * WHERE A = '${cityName}'`);
  const url2 = `${base}gid=${acsSheetId}&tq=${acsSheetQuery}`;
  // SWR Caching request
  const { data: datum2, error: error2, isLoading: isLoading2 } = useSWR(url2, fetcher);

  const data = { ...datum1, ...datum2 };
  const error = error1 || error2;
  const isLoading = isLoading1 || isLoading2;

  if (error || isLoading) {
    /* 
    Note: An error response will attempt to repeatedly retry the data fetch. 
    Because of this, we leave the same message for the loading state to prevent element 
    flickering on subsequent attempts
    */
    let message = '';
    if (isLoading) {
      message = 'No weather data currently available for this location';
    } else if (error) {
      message = 'No weather data currently available for this location';
    }

    return (
      <Box sx={{ ...baseContainerStyle, borderTop: '1px solid #16d3a9', borderBottom: '1px solid #16d3a9' }}>
        <Typography variant="h4">{message}</Typography>
      </Box>
    );
  }

  if (data) {
    return (
      <Box
        sx={{
          ...baseContainerStyle,
          flexDirection: 'column',
          backgroundColor,
          gap: 10,
          paddingTop: 12,
          paddingBottom: 12,
        }}
      >
        {/* Weather Data */}
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center' }}>
          <Typography variant="h3">{cityName} Today</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 3 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '5px' }}>
              {/* Note: Removed until further notice */}
              {/* <img
                style={{ width: '35px', position: 'relative', top: '2px' }}
                src={`/icons/weather/${getIcon(data.Weather)}`}
                onError={e => {
                  // Handle missing images
                  e.target.onError = null; // Prevent looping
                  e.target.style = 'display: none;';
                }}
                alt="Cloudy"
              /> */}
              <Typography variant="body1" style={weatherDataStyle}>
                {getWeatherString(data.Weather)}
              </Typography>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '5px' }}>
              <Typography variant="body1" style={weatherDataStyle}>
                {Number(data['Temp Max']).toFixed()}°
              </Typography>
              <Typography variant="body1" style={weatherDataStyle}>
                /
              </Typography>
              <Typography variant="body1" sx={{ color: '#747C82' }} style={weatherDataStyle}>
                {Number(data['Temp Min']).toFixed()}°
              </Typography>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 0 }}>
              <Typography variant="body1" style={weatherDataStyle}>
                {data.Humidity}
              </Typography>
              <img
                style={{ width: '35px', position: 'relative', top: '2px' }}
                src="/icons/weather/Humidity.svg"
                alt="Humidity"
              />
            </Box>
          </Box>
        </Box>

        {/* Housing Market Data */}
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, justifyContent: 'center', alignItems: 'center' }}>
          <Typography variant="h3">Housing Market Facts in {cityName}</Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignItems: { xs: 'center', md: 'start' },
              justifyContent: 'center',
              flexWrap: 'wrap',
              gap: { xs: 1, md: 4 },
            }}
          >
            <HousingMarketDetail title={`Houses sold last month in ${cityName}`} value={data['Houses Sold']} />

            <HousingMarketDetail
              title={`Average monthly rental cost in ${cityName}`}
              value={data['Rental Rate']}
              format="currency"
            />

            <HousingMarketDetail title={`Average home value in ${cityName}`} value={data['Home Value']} format="currency" />

            <HousingMarketDetail title={`Number of houses for sale in ${cityName}`} value={data['For Sale Inventory']} />

            <HousingMarketDetail
              title={`Median home asking price in ${cityName}`}
              value={data['Median List Price']}
              format="currency"
            />
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignItems: { xs: 'center', md: 'start' },
              justifyContent: 'center',
              flexWrap: 'wrap',
              gap: { xs: 1, md: 4 },
            }}
          >
            <HousingMarketDetail title={`% of Occupied Housing in ${cityName}`} value={data['Occupancy rate in city']} />

            <HousingMarketDetail
              title={`% of Owner-Occupied Homes in ${cityName}`}
              value={data['% of owner-occupied homes']}
            />

            <HousingMarketDetail title={`% of Rented Homes in ${cityName}`} value={data['% of renter-occupied homes']} />

            <HousingMarketDetail
              title={`Housing Costs as % of Homeowner Income in ${cityName}`}
              value={data['Homeowner cost as % of income']}
            />

            <HousingMarketDetail
              title={`Time Spent Commuting (Mean) in ${cityName}`}
              value={`${data['Mean travel time (minutes)']}mins`}
            />
          </Box>
        </Box>
      </Box>
    );
  }

  return null;
};

LocalWeatherBlock.propTypes = {
  /* Generate uid of block from graphcms */
  cityData: shape({}).isRequired,
  /* Block theme */
  theme: shape({
    backgroundColor: string.isRequired,
  }).isRequired,
};

export default LocalWeatherBlock;
