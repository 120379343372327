import React, { useState } from 'react';
import { arrayOf, shape, string } from 'prop-types';
import { Accordion, AccordionSummary, AccordionDetails, Box, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Markdown from '@/components/Markdown';

const ExpandableSection = ({
  expansionPairs,
  expansionGroupTitle,
  theme,
  theme: { backgroundColor, titleColor, iconColor },
}) => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box maxWidth={800} mx="auto">
      {expansionGroupTitle && (
        <Box pl={2} pb={1}>
          <Typography
            variant={expansionGroupTitle.variant}
            component={expansionGroupTitle.component}
            align={expansionGroupTitle.alignment}
          >
            {expansionGroupTitle.text}
          </Typography>
        </Box>
      )}
      <Box>
        {expansionPairs.map(pair => (
          <Accordion
            key={`expandable-${pair.expansionTitle}`}
            disableGutters
            elevation={0}
            expanded={expanded === pair.expansionTitle}
            onChange={handleChange(pair.expansionTitle)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: iconColor }} fontSize="large" />}
              aria-controls="panel1a-content"
              id={`${pair.expansionTitle}`}
              sx={{
                backgroundColor,
              }}
            >
              <Typography component={pair.titleComponent} variant={pair.titleVariant} color={titleColor}>
                {pair.expansionTitle}
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                backgroundColor,
              }}
            >
              <Markdown source={pair.description} theme={theme} />
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Box>
  );
};

ExpandableSection.propTypes = {
  /**
   * The dropdown title and its description when it's expanded.
   */
  expansionPairs: arrayOf(
    shape({
      expansionTitle: string.isRequired,
      titleComponent: string.isRequired,
      titleVariant: string.isRequired,
      description: shape({ compiledSource: string }).isRequired,
    }),
  ).isRequired,
  /** The title of the expandable section */
  expansionGroupTitle: shape({
    text: string.isRequired,
    variant: string.isRequired,
    component: string.isRequired,
    alignment: string.isRequired,
  }),
  theme: shape({
    backgroundColor: string.isRequired,
    titleColor: string.isRequired,
    bodyColor: string.isRequired,
    iconColor: string.isRequired,
  }).isRequired,
};

ExpandableSection.defaultProps = {
  expansionGroupTitle: null,
};

export default ExpandableSection;
