import React, { useState } from 'react';
import { arrayOf, shape, string } from 'prop-types';

import { Accordion, AccordionDetails, AccordionSummary, Box, useMediaQuery, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Link from '../../Link';

const useStyles = ({ theme, isStateHoverStyle }) => ({
  background: {
    background: 'none',
    '& > div:first-child': {
      minHeight: theme.spacing(6),
    },
    borderBottom: {
      xs: `2px solid ${theme.palette.divider}`,
      sm: 'none',
    },
    whiteSpace: 'nowrap',
    '&:before': {
      display: 'none',
    },
  },
  panelSummary: {
    padding: 1,
    '.MuiAccordionSummary-content': {
      flexGrow: { xs: 1, md: 0 },
    },
    '&.MuiAccordionSummary-root': {
      justifyContent: 'flex-start',
    },
    ...(isStateHoverStyle && {
      '&:hover': {
        color: theme.palette.primary.main,
      },
    }),
  },
  panelDetails: {
    flexDirection: 'column',
    px: { xs: 1, sm: 2 },
    py: { xs: 1, sm: 0 },
    mb: { xs: 1, sm: 2 },
  },
});

const CityListV2 = ({ citiesV2, cityTypes }) => {
  const citySections = cityTypes?.length
    ? cityTypes
    : [{ id: 'guides', serviceName: 'Guides', serviceNameSlug: 'moving-to' }];
  const isTabbed = citySections.length > 1;
  const isAllExpanded = citySections[0].id === 'guides';

  const theme = useTheme();
  const classes = useStyles({
    theme,
    isStateHoverStyle: !isAllExpanded,
  });
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const tablet = useMediaQuery(theme.breakpoints.down('md'));
  const cityList = citiesV2.reduce((states, { state, city, slug }) => {
    (states[state.state] = states[state.state] || []) // eslint-disable-line no-param-reassign
      .push({ city, state, slug });
    return states;
  }, {});

  const [expanded, setExpanded] = useState('');
  const [currentTab, setCurrentTab] = useState(isTabbed ? cityTypes[0].id : '');

  const handleExpand = state => (event, isExpanded) => {
    setExpanded(isExpanded ? state : false);
  };

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const mobileCols = 1;
  const tabletCols = 3;
  const desktopCols = 5;

  // Divides total number of states by desired number of columns to determine number
  // of states in each column. This is required to prevent reflow of one state to the
  // next column that would occur in a standard flex layout.
  const origStatesNum = Object.keys(cityList).length;
  let statesPerColumn = Math.ceil(origStatesNum / desktopCols);
  if (tablet) statesPerColumn = Math.ceil(origStatesNum / tabletCols);
  if (mobile) statesPerColumn = origStatesNum;

  let stateColumns = desktopCols;
  if (tablet) stateColumns = tabletCols;
  if (mobile) stateColumns = mobileCols;

  return (
    <Box display="flex" alignItems="center" flexDirection="column" width="100%">
      {isTabbed && (
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          variant={mobile ? 'fullWidth' : 'standard'}
          sx={{
            '& .MuiTab-root': {
              textTransform: 'none',
              fontSize: { xs: 20, md: 36 },
            },
            mb: 4,
          }}
        >
          {citySections.slice(0, 2).map(({ id, serviceName }) => (
            <Tab value={id} label={serviceName} />
          ))}
        </Tabs>
      )}
      <Box width={{ xs: '100%', md: '85%' }} display="flex" align="center">
        {[...Array(stateColumns)].map((_, i) => (
          <Box
            display="flex"
            flexDirection="column"
            width={{ xs: '100%', sm: 'calc(100% / 3)', lg: '20%' }}
            // Disabling eslint rule as docs state to not use rule when
            // breaking array into chunks which is what we're doing here
            // eslint-disable-next-line react/no-array-index-key
            key={i}
          >
            {Object.keys(cityList)
              .sort((a, b) => a.localeCompare(b))
              .slice(i * statesPerColumn, (i + 1) * statesPerColumn)
              .map(state => (
                <Accordion
                  elevation={0}
                  expanded={isAllExpanded || expanded === state}
                  onChange={handleExpand(state)}
                  square
                  disableGutters
                  key={`panel-${state}`}
                  sx={{ ...classes.background }}
                >
                  <AccordionSummary
                    sx={{ ...classes.panelSummary }}
                    expandIcon={isAllExpanded ? null : <ExpandMoreIcon sx={{ color: 'primary.main' }} fontSize="medium" />}
                  >
                    <Typography variant="h5">{state?.replace(/_/g, ' ')}</Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ ...classes.panelDetails }}>
                    {cityList[state]
                      .sort((a, b) => a.city.localeCompare(b.city))
                      .map(location => {
                        const citySlug = location.slug.substring(location.slug.indexOf('/') + 1);
                        const lowerFullState = location.state.state.toLowerCase().replace(/[" "]/g, '-');
                        const cityGuideHref = `/${citySections[0].serviceNameSlug}/${citySlug}-${lowerFullState}/`;
                        return (
                          <Box key={`link-${location.city}-${location.state}`}>
                            {citySections.slice(0, 2).map(({ id, serviceNameSlug: path }) => (
                              <Link
                                key={`link-${location.city}-${location.state}-${id}`}
                                underline="none"
                                href={isAllExpanded ? cityGuideHref : `/${location.slug}/${path}/`}
                                color={isAllExpanded ? 'primary' : 'textSecondary'}
                                variant="body2"
                                gutterBottom
                                display={!isTabbed || currentTab === id ? 'block' : 'none'}
                              >
                                {location.city}
                              </Link>
                            ))}
                          </Box>
                        );
                      })}
                  </AccordionDetails>
                </Accordion>
              ))}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

CityListV2.propTypes = {
  citiesV2: arrayOf(
    shape({
      city: string.isRequired,
      state: shape({}),
      slug: string.isRequired,
    }),
  ).isRequired,
  cityTypes: arrayOf(
    shape({
      id: string.isRequired,
      serviceName: string.isRequired,
      serviceNameSlug: string.isRequired,
    }),
  ).isRequired,
};

export default CityListV2;
