import React from 'react';
import { string } from 'prop-types';

import { Box, Typography } from '@mui/material';

import Button from '@/components/Button';

const Thanks = ({ title, description, buttonText }) => (
  <Box p={2} display="flex" flexDirection="column" alignItems="center">
    <Typography
      component="h3"
      variant="formTitle"
      sx={{
        mb: 3,
        mt: 3,
        textAlign: 'center',
        color: 'secondary.main',
      }}
    >
      {title}
    </Typography>
    <Typography component="body2" variant="body1" sx={{ mb: 3, width: 360, textAlign: 'center' }}>
      {description}
    </Typography>
    <Button variant="contained" sx={{ my: 3, mb: 2.5, width: 310 }} href="/book/service/">
      {buttonText}
    </Button>
  </Box>
);

Thanks.propTypes = {
  buttonText: string.isRequired,
  title: string.isRequired,
  description: string.isRequired,
};

export default Thanks;
