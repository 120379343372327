import React, { createRef } from 'react';
import { func, string } from 'prop-types';

import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import DialpadIcon from '@mui/icons-material/Dialpad';
import HearingIcon from '@mui/icons-material/Hearing';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';

const FormInput = ({ label, type, apiInputName, onChange, icon }) => {
  const ref = createRef();

  const adornmentIcons = {
    phone: LocalPhoneOutlinedIcon,
    person: PersonOutlineIcon,
    mail: MailOutlineIcon,
    destination: LocationOnOutlinedIcon,
    startLocation: CircleOutlinedIcon,
    calendar: CalendarTodayIcon,
    business: EmojiTransportationIcon,
    numberPad: DialpadIcon,
    hear: HearingIcon,
    size: AspectRatioIcon,
    truck: LocalShippingIcon,
  };

  const IconComponent = adornmentIcons[icon];

  return (
    <OutlinedInput
      required
      inputRef={ref}
      startAdornment={
        <InputAdornment position="start">
          {IconComponent ? <IconComponent color="secondary" fontSize="medium" /> : null}
        </InputAdornment>
      }
      sx={{
        height: 55,
        width: '100%',
        input: {
          fontSize: '1.25rem',
        },
      }}
      placeholder={label}
      type={type}
      name={apiInputName}
      onChange={e => onChange(e, apiInputName)}
      inputProps={{
        style: { WebkitBoxShadow: '0 0 0 1000px white inset' },
      }}
    />
  );
};

FormInput.propTypes = {
  label: string.isRequired,
  type: string.isRequired,
  apiInputName: string.isRequired,
  onChange: func.isRequired,
  icon: string.isRequired,
};

export default FormInput;
