import React from 'react';
import { shape, string, arrayOf, oneOf } from 'prop-types';
import { Box, Typography } from '@mui/material';

import Markdown from '@/components/Markdown';
import { TEXT_TYPE } from '@/types';
import FormCard from './FormCard';

const SalesforceFormBlock = ({
  formName,
  formType,
  title,
  align,
  salesforceFormInputs,
  buttonText,
  formIcon,
  submittedTitle,
  submittedDescription,
  submittedButtonText,
  submittedIcon,
  textBlockHeading,
  textBlock,
  theme,
  leadType,
  leadType2,
  leadSource,
}) =>
  salesforceFormInputs?.length > 0 && (
    <Box display="flex" justifyContent={align}>
      <Box
        order={align === 'right' ? 1 : 2}
        flexDirection="column"
        sx={{ px: 4, pt: 1, display: { xs: 'none', md: 'flex' } }}
        maxWidth={600}
        textAlign="justify"
        mx="auto"
      >
        {textBlockHeading && (
          <Typography
            color={theme.titleColor}
            variant={textBlockHeading.variant}
            component={textBlockHeading.component}
            align={textBlockHeading.alignment}
          >
            {textBlockHeading.text}
          </Typography>
        )}
        <Markdown source={textBlock} theme={theme} />
      </Box>
      <FormCard
        formName={formName}
        formType={formType}
        title={title}
        inputs={salesforceFormInputs}
        buttonText={buttonText}
        formIcon={formIcon}
        submittedTitle={submittedTitle}
        submittedDescription={submittedDescription}
        submittedButtonText={submittedButtonText}
        submittedIcon={submittedIcon}
        leadRecordType={leadType}
        leadType={leadType2}
        leadSource={leadSource}
      />
    </Box>
  );

SalesforceFormBlock.propTypes = {
  // Used for tracking submissions
  formName: string.isRequired,
  /** The type of form ("lead" or "case") */
  formType: oneOf(['lead', 'case']).isRequired,
  /** Where to align the form */
  align: oneOf(['left', 'right']).isRequired,
  /** The text of the form button pre-submission */
  buttonText: string.isRequired,
  /** The text of the form button post-submission */
  submittedButtonText: string.isRequired,
  /** The url of the image of the pre-submission icon and its alt */
  formIcon: shape({
    url: string.isRequired,
    alt: string.isRequired,
  }).isRequired,
  /** The url of the image of the post-submission icon and its alt */
  submittedIcon: shape({
    url: string.isRequired,
    alt: string.isRequired,
  }).isRequired,
  /** The values for a given input field. One object is one input field.
   */
  salesforceFormInputs: arrayOf(
    shape({
      /** The name value given by the Salesforce web-to-lead or web-to-case generator. */
      apiInputName: string,
      /** The placeholder value in the input field. */
      label: string,
      /** See See https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input for possible input types. */
      type: string,
    }),
  ).isRequired,
  /** The title of the form pre-submission. */
  title: string.isRequired,
  /** The title of the form post-submission. */
  submittedTitle: string.isRequired,
  /** The description post-submission. */
  submittedDescription: string.isRequired,
  /** The heading of the text block. */
  textBlockHeading: TEXT_TYPE,
  /** The markdown text to be displayed next to the form. */
  textBlock: shape({
    compiledSource: string.isRequired,
  }).isRequired,
  theme: shape({}).isRequired,
  /** The lead record type for a lead form. */
  leadType: string.isRequired,
  /** The lead type for a lead form. */
  leadType2: string.isRequired,
  /** The lead record source for a lead form. */
  leadSource: string,
};

SalesforceFormBlock.defaultProps = {
  textBlockHeading: null,
  leadSource: 'Bellhop Site',
};

export default SalesforceFormBlock;
