import React from 'react';
import Link from 'next/link';
import { node } from 'prop-types';
import { LINK_TYPE } from '@/types';

const CardWrapper = ({ link, children }) =>
  link ? (
    <Link href={`${link.href}${typeof window !== 'undefined' ? window.location.search : ''}`}>{children}</Link>
  ) : (
    children
  );

CardWrapper.propTypes = {
  link: LINK_TYPE,
  children: node.isRequired,
};

CardWrapper.defaultProps = {
  link: null,
};

export default CardWrapper;
