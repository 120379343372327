import React, { useEffect, useState } from 'react';
import { arrayOf, number, oneOf, shape, string } from 'prop-types';
import GraphImage from '@karpack/react-image';
import { Box, Typography } from '@mui/material';

import Markdown from '@/components/Markdown';
import Button from '@/components/Button';

import { BUTTON_TYPE } from '@/types';
import VideoStage from './VideoStage';

const VideoBlock = ({
  id,
  alignment,
  videoUrl,
  title,
  description,
  buttons,
  thumbnail: { handle: thumbnailHandle, alt: thumbnailAlt, width: thumbnailWidth, height: thumbnailHeight },
  theme,
}) => {
  const [videoStageOpen, setVideoStageOpen] = useState(false);
  const left = alignment === 'left';

  useEffect(() => {
    const docElem = document.documentElement;
    if (videoStageOpen) {
      docElem.classList.add('no-scroll');
    } else {
      docElem.classList.remove('no-scroll');
    }
  }, [videoStageOpen]);

  return (
    <>
      <Box
        display="flex"
        flexDirection={{
          xs: 'column',
          md: left ? 'row' : 'row-reverse',
        }}
        alignItems="center"
        height="fit-content"
      >
        <Box
          width={{
            xs: '100%',
            md: '50%',
          }}
          maxWidth={550}
          position="relative"
        >
          <Box position="absolute" left={-32} bottom={-26}>
            {/* Dots background wrapper */}
            <img src="/images/dot-bg.svg" alt="dot background" width={173} height={173} loading="lazy" />
          </Box>
          <Box borderRadius={4} overflow="hidden">
            <GraphImage /* Main video thumbnail / preview */
              image={{
                handle: thumbnailHandle,
                width: thumbnailWidth,
                height: thumbnailHeight,
              }}
              alt={thumbnailAlt}
              maxWidth={550}
              withWebp
              blurryPlaceholder={false}
            />
            <Box /* Play icon wrapper */
              onClick={() => {
                setVideoStageOpen(true);
              }}
              position="absolute"
              top={0}
              right={0}
              bottom={0}
              left={0}
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                '&:hover': {
                  boxShadow: 'inset 0 0 100px 20px rgba(255, 255, 255, 0.5)',
                  cursor: 'pointer',
                },
              }}
            >
              <img src="/images/video-play.svg" alt="play icon" width={100} height={100} loading="lazy" />
            </Box>
          </Box>
        </Box>
        <Box
          flexGrow={{ md: 1 }}
          pt={{ xs: 6, md: 0 }}
          pr={{
            md: left ? 0 : 4,
            lg: left ? 0 : 10,
          }}
          pl={{
            md: left ? 4 : 0,
            lg: left ? 10 : 0,
          }}
          width={{
            xs: '100%',
            md: '50%',
          }}
          minWidth={{ md: 500 }}
        >
          <Typography variant="h3" component="p" color={theme.titleColor} width={{ md: 500 }} pb={2}>
            {title}
          </Typography>
          <Box width={{ md: 500 }}>
            <Markdown source={description} theme={theme} width={{ md: 500 }} />
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            m={{ xs: 'auto', sm: 0 }}
            pt={{
              xs: buttons ? 2 : 0,
              md: buttons ? 5 : 0,
            }}
            alignItems={{
              xs: buttons ? 'center' : 'start',
              sm: 'start',
            }}
          >
            {buttons.map(({ id: buttonId, label, href, color, variant }) => (
              <Box
                key={`${id}_${buttonId}`} /* uid of block + uid of button for unique key */
                width={{
                  xs: '100%',
                  md: 'auto',
                }}
                flexGrow={1}
                maxWidth={{ md: '250px' }}
                mb={{ md: 1 }}
                mr={{ md: 2 }}
              >
                <Button variant={variant} color={color} href={href} py={4}>
                  {label}
                </Button>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
      {videoStageOpen && <VideoStage setVideoStageOpen={setVideoStageOpen} videoUrl={videoUrl} />}
    </>
  );
};

VideoBlock.propTypes = {
  /* Generate uid of block from graphcms */
  id: string.isRequired,
  /* Text form of alignment. Expected left or right */
  alignment: oneOf(['left', 'right']).isRequired,
  videoUrl: string.isRequired,
  title: string.isRequired,
  description: shape({
    compiledSource: string.isRequired,
  }).isRequired,
  /* Array of buttons. One object is one button */
  buttons: arrayOf(BUTTON_TYPE).isRequired,
  thumbnail: shape({
    handle: string.isRequired,
    alt: string,
    width: number.isRequired,
    height: number.isRequired,
  }),
  theme: shape({
    backgroundColor: string.isRequired,
    titleColor: string.isRequired,
    bodyColor: string.isRequired,
  }).isRequired,
};

VideoBlock.defaultProps = {
  thumbnail: null,
};

export default VideoBlock;
