import React from 'react';
import { useRouter } from 'next/router';
import { Box, Typography } from '@mui/material';
import GoogleMaps from '@/components/CostCalculator/Calculator';
import CostCalculator from '@/components/CostCalculator/Results';
import Link from '../../Link';

const CalculatorBlock = () => {
  const router = useRouter();

  const initialState = {
    caption: 'COST CALCULATOR',
    title: 'Get a quick estimate for your local move.',
    description: 'Estimates based on over 300,000 moves.',
    Component: GoogleMaps,
    cta: {
      text: 'Have a corporate move?',
      href: '/moving-services/office',
    },
  };
  const marketFound = {
    caption: 'COST CALCULATOR',
    title: 'Here are some options for your move.',
    description:
      'Prices are an estimate and do not reflect availability. Bellhop provides customize a quote specific to your move which will be affected by your inventory, exact locations, date, etc.',
    Component: CostCalculator,
  };

  const getContent = () => {
    if (router.query.market) return marketFound;
    return initialState;
  };

  const content = getContent();

  return (
    <Box display="flex" flexDirection="column" alignItems="center" py={{ md: 11 }}>
      <Box
        width={{ xs: '100%', md: 630 }}
        display="flex"
        flexDirection="column"
        alignItems={{ xs: 'flex-start', md: 'center' }}
      >
        <Typography variant="caption" color="secondary">
          {content.caption}
        </Typography>
        <Typography variant="h3">{content.title}</Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          sx={{
            textAlign: {
              sm: 'left',
              md: 'center',
            },
            mb: 1,
          }}
        >
          {content.description}
        </Typography>
      </Box>
      {content.Component && <content.Component />}
      {content.cta && (
        <Box mt={1}>
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{
              textAlign: {
                sm: 'left',
                md: 'center',
              },
              mb: 1,
            }}
          >
            {content.cta.text}
            <Link href={content.cta.href} sx={{ ml: 1, fontSize: theme => theme.typography.body2.fontSize }}>
              Learn more
            </Link>
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default CalculatorBlock;
