/* eslint-disable consistent-return */
import React, { useState, useRef, useEffect, useMemo } from 'react';
import { useRouter } from 'next/router';
import { Box, TextField, Autocomplete, Grid, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Form from '@/components/Form';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import { getLocation } from '../../../lib/api';
import loadScript from '../../../utils/loadScript';

const autocompleteService = { current: null };

const Calculator = () => {
  const router = useRouter();

  // Form state
  const [loading, setLoading] = useState(false);
  const [invalid, setInvalid] = useState(false);

  // Value state
  const [location, setLocation] = useState(null);
  const [property, setProperty] = useState(null);
  const [size, setSize] = useState(null);
  const [{ market, address }, setGeo] = useState({ market: '', address: '' });

  // Autocomplete state
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const loaded = useRef(false);

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${process.env.NEXT_PUBLIC_GOOGLE_MAPS_KEY}&libraries=places`,
        document.querySelector('head'),
        'google-maps',
      );
    }

    loaded.current = true;
  }

  const fetch = useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    [],
  );

  useEffect(() => {
    if (!property || !size) return;

    if (!market) {
      const params = new URLSearchParams(address).toString().replaceAll('&', '-').replaceAll('=', '_');

      return router.push(`${process.env.NEXT_PUBLIC_MARKETING_DOMAIN}/book/lead/?referrer=mcc&locations=${params}`);
    }

    router.push({
      pathname: '/moving-costs-calculator',
      query: {
        market,
        property,
        size: property === 'Other' ? '1-1000 SQFT' : size,
      },
    });
  }, [market]);

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(location ? [location] : []);
      return undefined;
    }

    fetch({ input: inputValue, types: ['geocode'], componentRestrictions: { country: ['us'] } }, results => {
      if (active) {
        let newOptions = [];

        if (location) {
          newOptions = [location];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [location, inputValue, fetch]);

  const getAddressParts = parts => {
    const types = [
      'route',
      'locality',
      'neighborhood',
      'administrative_area_level_1',
      'country',
      'postal_code',
      'plus_code',
    ];
    return parts.reduce((acc, part) => {
      part.types.forEach(type => {
        if (types.includes(type)) {
          acc[type] = part.short_name;
        }
      });
      return acc;
    }, {});
  };

  const normalizeParts = parts => ({
    line1: parts.route || parts.plus_code,
    city: parts.locality ? parts.locality : parts.neighborhood,
    region: parts.administrative_area_level_1,
    country: parts.country,
    postalCode: parts.postal_code,
  });

  const onValid = () => {
    setInvalid(false);
    setLoading(true);
    // Check if valid google place value exists
    if (location.place_id) {
      // Initiate google geocoder
      const geocoder = new window.google.maps.Geocoder();
      // Geocode request on placeId
      geocoder.geocode({ placeId: location.place_id }).then(response => {
        const loc = response.results[0];
        if (loc.geometry.location_type === 'APPROXIMATE') {
          // If state, then don't try to find a market
          if (loc.types.includes('administrative_area_level_1')) {
            setGeo({
              market: loc.address_components.find(component => component.types.includes('administrative_area_level_1'))
                .short_name,
            });
          } else {
            // If couldn't find an exact address, try with the lat/lng
            const latLng = {
              lat: loc.geometry.location.lat(),
              lng: loc.geometry.location.lng(),
            };
            geocoder.geocode({ location: latLng }).then(r => {
              const addy = normalizeParts(getAddressParts(r.results[1].address_components));
              getLocation(addy).then(ret => {
                setGeo({ market: ret.location.marketCode, address: addy });
              });
            });
          }
        } else {
          // Happy path
          const addy = normalizeParts(getAddressParts(loc.address_components));
          getLocation(addy).then(ret => {
            setGeo({ market: ret.location.marketCode, address: addy });
          });
        }
      });
    }
  };

  const autocompleteStyles = {
    width: { xs: '100%', md: 280 },
    mb: { xs: 3 },
    '& label.MuiInputLabel-root, & div.MuiInputBase-root,	': {
      fontSize: theme => `${theme.typography.body1.fontSize}`,
    },
  };

  return (
    <Box width="100%">
      <Form onValid={onValid} onInvalid={() => setInvalid(true)}>
        <Box
          display="flex"
          width={{ xs: '100%', md: 900 }}
          margin="0 auto"
          flexDirection={{ xs: 'column', md: 'row' }}
          justifyContent="space-around"
          py={{ xs: 2, md: 4 }}
        >
          <Autocomplete
            id="google-map-demo"
            sx={autocompleteStyles}
            getOptionLabel={option => (typeof option === 'string' ? option : option.description)}
            filterOptions={x => x}
            options={options}
            autoComplete
            freeSolo
            includeInputInList
            filterSelectedOptions
            value={location}
            onChange={(event, newValue) => {
              setOptions(newValue ? [newValue, ...options] : options);
              setLocation(newValue);
            }}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            renderInput={params => (
              <TextField
                {...params}
                required
                error={invalid && location === null}
                label="Starting location"
                color="secondary"
                fullWidth
                InputLabelProps={{ ...params.InputLabelProps }}
                inputProps={{ ...params.inputProps }}
              />
            )}
            renderOption={(props, option) => {
              const matches = option.structured_formatting.main_text_matched_substrings;
              const parts = parse(
                option.structured_formatting.main_text,
                matches.map(match => [match.offset, match.offset + match.length]),
              );

              return (
                <li {...props}>
                  <Grid container alignItems="center">
                    <Grid item>
                      <Box component={LocationOnOutlinedIcon} sx={{ color: 'text.secondary', mr: 2 }} />
                    </Grid>
                    <Grid item xs>
                      <Typography gutterBottom={false} noWrap sx={{ lineHeight: 1 }}>
                        {parts.map(part => (
                          <Typography
                            key={part.text}
                            variant="body2"
                            component="span"
                            sx={{
                              fontWeight: part.highlight ? 700 : 400,
                              lineHeight: 1,
                            }}
                          >
                            {part.text}
                          </Typography>
                        ))}
                      </Typography>

                      <Typography variant="caption" color="text.secondary" noWrap sx={{ lineHeight: 1 }}>
                        {option.structured_formatting.secondary_text}
                      </Typography>
                    </Grid>
                  </Grid>
                </li>
              );
            }}
          />
          <Autocomplete
            sx={autocompleteStyles}
            options={['Home', 'Apartment/Condo', 'Other']}
            disableClearable
            value={property}
            onChange={(event, newValue) => {
              setProperty(newValue);
            }}
            renderInput={params => (
              <TextField
                {...params}
                required
                error={invalid && property === null}
                label="Property type"
                color="secondary"
                fullWidth
                InputLabelProps={{ ...params.InputLabelProps }}
                inputProps={{ ...params.inputProps }}
              />
            )}
          />
          <Autocomplete
            sx={autocompleteStyles}
            options={['Few items', 'Studio - 1BR', '2BR+']}
            value={size}
            disabled={property === 'Other'}
            onChange={(event, newValue) => {
              setSize(newValue);
            }}
            disableClearable
            renderInput={params => (
              <TextField
                {...params}
                required
                error={invalid && size === null}
                label="How much are you moving?"
                color="secondary"
                fullWidth
                InputLabelProps={{ ...params.InputLabelProps }}
                inputProps={{ ...params.inputProps }}
              />
            )}
          />
        </Box>
        <Box width={{ xs: '100%', md: 240 }} margin="0 auto">
          <LoadingButton variant="contained" type="submit" loading={loading} disabled={loading}>
            Get estimate
          </LoadingButton>
        </Box>
      </Form>
    </Box>
  );
};

export default Calculator;
