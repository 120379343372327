import React, { useState } from 'react';
import { string, number, bool, oneOfType, shape } from 'prop-types';
import { Box, Typography, Rating, Collapse, Link } from '@mui/material';

const QuoteBody = ({ quote, author, rating, date, expandable, sx }) => {
  const [expanded, setExpanded] = useState(false);
  const dateValues = new Date(date).toDateString().split(' ').splice(1, 3);

  const handleChange = () => {
    setExpanded(prev => !prev);
  };

  // Handles truncating large quotes
  let quoteString = quote;
  let longQuote = false;
  if (quote.length > 200) {
    quoteString = !expanded ? `${quote.slice(0, 200)}...` : quote;
    longQuote = true;
  }

  return (
    <Box sx={{ padding: 3, ...sx }}>
      <Box sx={{ display: 'flex', gap: 1 }}>
        <Box
          sx={{
            background: '#6994F4',
            borderRadius: '30px',
            height: '32px',
            width: '32px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="h4" color="white">
            {author[0]}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="caption" sx={{ lineHeight: '1em' }} color="textSecondary">
            {author}
          </Typography>
          <Typography variant="p" sx={{ fontSize: '12px' }} color="textSecondary">
            {dateValues[0]} {dateValues[1]}, {dateValues[2]}
          </Typography>
        </Box>
      </Box>
      <Rating value={Number(rating)} size="small" sx={{ color: '#6994F4' }} precision={0.5} readOnly />
      {expandable ? (
        <Collapse orientation="vertical" in={expanded} sx={{ visibility: 'visible', height: 'auto !important' }}>
          <Typography variant="body2" sx={{ display: 'inline-block' }}>
            {quoteString}
            {expandable && (
              <Link
                component="button"
                style={{
                  ...((expanded || !longQuote) && { display: 'none' }),
                  cursor: 'pointer',
                  color: '#16D3A9',
                  marginLeft: '5px',
                  textDecoration: 'none',
                }}
                onClick={handleChange}
              >
                See more
              </Link>
            )}
          </Typography>
        </Collapse>
      ) : (
        <Typography
          variant="body2"
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: '3',
            WebkitBoxOrient: 'vertical',
          }}
        >
          {quoteString}
        </Typography>
      )}
      {expandable && (
        <Link
          component="button"
          sx={{ ...((!expanded || !longQuote) && { display: 'none' }), textDecoration: 'none', cursor: 'pointer' }}
          variant="body2"
          color="primary"
          onClick={handleChange}
        >
          See less
        </Link>
      )}
    </Box>
  );
};

QuoteBody.propTypes = {
  quote: string.isRequired,
  author: string.isRequired,
  rating: oneOfType([number, string]).isRequired,
  date: string.isRequired,
  expandable: bool,
  sx: shape({}),
};

QuoteBody.defaultProps = {
  expandable: true,
  sx: {},
};

export default QuoteBody;
