import { useEffect } from 'react';

const useTrustPilotScript = () => {
  useEffect(() => {
    const hasScript = document.getElementById('trustPilot');
    if (!hasScript) {
      const tag = document.createElement('script');
      tag.async = true;
      tag.src = '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';
      tag.id = 'trustPilot';
      const body = document.getElementsByTagName('body')[0];
      body.appendChild(tag);
    }
  }, []);
};

export default useTrustPilotScript;
