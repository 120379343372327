import React from 'react';
import { shape } from 'prop-types';
import { Box } from '@mui/material';
import { useRouter } from 'next/router';
import Link from '../../Link';

const NearbyCity = ({ cityData }) => {
  const { asPath } = useRouter();
  const pathArr = asPath.split('/').filter(p => p);

  return (
    <Box display="flex" alignItems="center" flexDirection="column" width="100%">
      <Box width={{ xs: '100%', md: '80%' }} display="flex" justifyContent="center" mx="auto" flexWrap="wrap">
        {cityData?.nearbyCities?.map(city => (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            width={{ xs: '100%', sm: 'calc(100% / 3)', lg: '20%' }}
            // Disabling eslint rule as docs state to not use rule when
            // breaking array into chunks which is what we're doing here
            // eslint-disable-next-line react/no-array-index-key
            key={city.slug}
          >
            <Box width={{ xs: '100%', md: '85%' }} key={city.slug} textAlign="center">
              <Link gutterBottom variant="body1" href={`/${city.slug}/${pathArr[2]}/`}>
                {city.city}
              </Link>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

NearbyCity.propTypes = {
  cityData: shape({}),
};

NearbyCity.defaultProps = {
  cityData: null,
};

export default NearbyCity;
