import React from 'react';
import { Grid } from '@mui/material';
import { arrayOf, shape, string } from 'prop-types';

import { LINK_TYPE } from '@/types';
import ImageCard from './ImageCard';

const ImageCardsBlock = ({ imageCards, theme }) => (
  <Grid container spacing={3} justifyContent="center">
    {imageCards.map(({ id, ...props }) => (
      <Grid item key={id} xs="12" sm="6" lg={imageCards.length % 2 ? 4 : 6}>
        <ImageCard {...props} theme={theme} />
      </Grid>
    ))}
  </Grid>
);

ImageCardsBlock.propTypes = {
  /** Array of image cards */
  imageCards: arrayOf(
    shape({
      id: string,
      /** Full width image */
      image: shape({
        id: string,
        label: string,
        url: string,
      }),
      /** Title of the card */
      title: string.isRequired,
      /** Markdown content in the card */
      content: shape({}),
      /** Link at bottom of card */
      link: LINK_TYPE,
    }),
  ).isRequired,
  /** Block theme */
  theme: shape({
    backgroundColor: string.isRequired,
    titleColor: string.isRequired,
    bodyColor: string.isRequired,
    iconColor: string.isRequired,
  }).isRequired,
};

export default ImageCardsBlock;
