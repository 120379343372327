export default async function fetchBellhopAPI(query, { variables } = {}) {
  const res = await fetch(`https://${process.env.NEXT_PUBLIC_API_HOSTNAME}/graphql`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query,
      variables,
    }),
  });
  const json = await res.json();

  if (json.errors) {
    // eslint-disable-next-line no-console
    console.log('ERROR', json.errors);
    throw new Error('Failed to fetch API');
  }

  return json.data;
}
