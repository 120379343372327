import React from 'react';
import Button from '@/components/Button';
import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import { arrayOf, func, shape, string } from 'prop-types';
import FormInput from '../Input';

const Form = ({ title, inputs, onChange, buttonText, onSubmit }) => {
  const getInputWidth = index => (inputs[inputs.length - 1] === inputs[index] && index % 2 === 0 ? 12 : 6);

  const matches = useMediaQuery(theme => theme.breakpoints.up('md'));

  return (
    <Box my={3} display="flex" flexDirection="column" alignItems="center">
      <Box>
        <Typography
          component="h3"
          variant="formTitle"
          sx={{
            mb: 5,
            mt: 2,
            textAlign: 'center',
          }}
        >
          {title}
        </Typography>
      </Box>

      <Grid container spacing={{ xs: 1, md: 2 }}>
        {inputs.map(({ type, label, icon, apiInputName, required, defaultValue, display }, i) =>
          !display || display === 'show' ? (
            <Grid key={label} item xs={matches ? getInputWidth(i) : 12}>
              <FormInput
                key={label}
                type={type}
                label={label}
                icon={icon}
                name={apiInputName}
                required={required === 'required'}
                defaultValue={defaultValue}
                onChange={onChange}
                display={display}
              />
            </Grid>
          ) : null,
        )}
      </Grid>
      <Button variant="contained" sx={{ mt: { xs: 4, md: 5 }, mb: 2.5, width: { xs: 280, md: 310 } }} onClick={onSubmit}>
        {buttonText}
      </Button>
    </Box>
  );
};

Form.propTypes = {
  title: string.isRequired,
  inputs: arrayOf(
    shape({
      type: string,
      label: string,
      icon: string,
      apiInputName: string,
      required: string,
      defaultValue: string,
      display: string,
    }),
  ).isRequired,
  onChange: func.isRequired,
  buttonText: string.isRequired,
  onSubmit: func.isRequired,
};

export default Form;
