export default async function createLead(data) {
  const shapedData = remapDataKeys(data);
  try {
    const url = `${process.env.NEXT_PUBLIC_PARTNER_API}/api/partner-api/v2/leads/internal`;

    const options = {
      method: "POST",
      body: JSON.stringify(shapedData),
    };

    return await fetch(url, options);
  } catch (error) {
    return null;
  }
}

const remapDataKeys = (data) => {
  let newShape = {};
  Object.keys(data).map((k) => {
    let newKey = k;
    switch (k) {
      case "firstName":
        newKey = "first_name";
        break;
      case "lastName":
        newKey = "last_name";
        break;
      default:
        break;
    }
    newShape[newKey] = data[k];
  });
  return newShape;
};
