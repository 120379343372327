import React from 'react';
import { arrayOf, string, shape } from 'prop-types';
import { OutlinedInput, Select } from '@mui/material';

const SalesforceSelect = ({ label, children = [] }) => (
  <Select
    native
    variant="outlined"
    defaultValue={label}
    input={
      <OutlinedInput
        required
        sx={{
          height: 55,
          width: '100%',
          color: theme => theme.palette.text.default,
          '.MuiNativeSelect-select': {
            paddingBottom: 2.75,
            fontSize: theme => theme.typography.body1.fontSize,
            lineHeight: theme => theme.typography.body1.lineHeight,
          },
        }}
        placeholder={label}
        name={label}
      />
    }
  >
    <>
      <option hidden disabled>
        {label}
      </option>
      {children.length ? children.map(({ props }) => <option {...props} />) : <option {...children.props} />}
    </>
  </Select>
);

SalesforceSelect.propTypes = {
  label: string.isRequired,
  children: arrayOf(shape({})).isRequired,
};

export default SalesforceSelect;
