import React from 'react';
import { func, arrayOf, shape, string } from 'prop-types';
import { useMediaQuery, Box, Grid, Typography, Select, MenuItem, InputLabel } from '@mui/material';

import Button from '@/components/Button';
import FormInput from '../Input';

const Form = ({ inputs, onChange, onSubmit, title, buttonText, formType }) => {
  const getInputWidth = index => (inputs[inputs.length - 1] === inputs[index] && index % 2 === 0 ? 12 : 6);

  const matches = useMediaQuery(theme => theme.breakpoints.up('md'));

  return (
    <Box my={3} display="flex" flexDirection="column" alignItems="center">
      <Box>
        <Typography
          component="h3"
          variant="formTitle"
          sx={{
            mb: 5,
            mt: 2,
            textAlign: 'center',
          }}
        >
          {title}
        </Typography>
      </Box>
      <Grid container spacing={{ xs: 1, md: 2 }}>
        {inputs.map(({ label, type, apiInputName, icon }, i) => (
          <Grid key={label} item xs={matches ? getInputWidth(i) : 12}>
            <FormInput key={label} label={label} type={type} apiInputName={apiInputName} icon={icon} onChange={onChange} />
          </Grid>
        ))}
        {formType === 'case' && (
          <>
            <Box
              sx={{
                pl: 3,
                pt: 2,
                mb: -2,
              }}
            >
              <InputLabel id="label-id">Primary Case Reason:</InputLabel>
            </Box>
            <Select
              id="00N4p00000FlZ04"
              labelId="label-id"
              onChange={e => onChange(e, '00N4p00000FlZ04')}
              displayEmpty
              defaultValue=""
              sx={{
                mt: 2,
                height: 55,
                width: '100%',
                ml: 2,
              }}
            >
              <MenuItem value="Packing Materials Inquiry">Packing Materials Inquiry</MenuItem>
              <MenuItem value="Certificate of Insurance Request">Certificate of Insurance Request</MenuItem>
              <MenuItem value="Update/Add Address">Update/Add Address</MenuItem>
              <MenuItem value="Reschedule Order">Reschedule Order</MenuItem>
              <MenuItem value="Cancel Order">Cancel Order</MenuItem>
              <MenuItem value="No Show">No Show</MenuItem>
              <MenuItem value="Truck No Show">Truck No Show</MenuItem>
            </Select>
          </>
        )}
      </Grid>
      <Button variant="contained" sx={{ mt: { xs: 4, md: 5 }, mb: 2.5, width: { xs: 280, md: 310 } }} onClick={onSubmit}>
        {buttonText}
      </Button>
    </Box>
  );
};

Form.propTypes = {
  buttonText: string.isRequired,
  inputs: arrayOf(
    shape({
      apiInputName: string,
      label: string,
      type: string,
    }),
  ).isRequired,
  title: string.isRequired,
  onChange: func.isRequired,
  onSubmit: func.isRequired,
  formType: string.isRequired,
};

export default Form;
