import React, { createRef } from 'react';

import { string, shape } from 'prop-types';

import { Box } from '@mui/material';

import { useTrustPilotScript } from '../../../modules';

const TrustPilotBlock = ({ templateId, stars, location, schemaType, theme: { trustPilotTheme } }) => {
  const ref = createRef();
  useTrustPilotScript();

  return (
    <Box mt={5} mx="auto" maxWidth={{ md: 1000, xs: 400 }}>
      <div
        ref={ref}
        className="trustpilot-widget"
        data-businessunit-id="5458f3a400006400057b5630"
        data-locale="en-US"
        data-template-id={templateId}
        data-style-height="140px"
        data-style-width="100%"
        data-theme={trustPilotTheme}
        data-stars={stars}
        data-review-languages="en"
        data-allow-robots="true"
        data-location={location}
        data-schema-type={schemaType}
      >
        <a href="https://www.trustpilot.com/review/www.getbellhops.com" target="_blank" rel="noopener noreferrer">
          Trustpilot
        </a>
      </div>
    </Box>
  );
};

TrustPilotBlock.propTypes = {
  templateId: string.isRequired,
  stars: string.isRequired,
  location: string.isRequired,
  schemaType: string.isRequired,
  theme: shape({
    trustPilotTheme: string.isRequired,
  }).isRequired,
};

export default TrustPilotBlock;
