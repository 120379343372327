import React from 'react';
import { Box } from '@mui/material';
import { arrayOf, shape } from 'prop-types';
import { useRouter } from 'next/router';
import Link from '../../Link';

const NeighborhoodList = ({ neighborhoods }) => {
  const { asPath } = useRouter();
  const pathArr = asPath.split('/').filter(p => p);

  const neighborhoodComponent = neighborhood => {
    // Parse out only the relevant path segments for assembling the slug
    // Eliminates url params to determine the service, but allows params to persist through navigation
    const pathSegments = pathArr.filter(v => v[0] !== '?');
    return (
      <Box width={{ xs: '100%', md: '85%' }} display="flex" align="center" key={neighborhood.slug}>
        <Link gutterBottom variant="body2" href={`/${neighborhood.slug}/${pathSegments[pathSegments.length - 1]}/`}>
          {neighborhood.neighborhood}
        </Link>
      </Box>
    );
  };

  return (
    <Box display="flex" alignItems="center" flexDirection="column" width="100%">
      <Box width={{ xs: '100%', md: '80%' }} display="flex" align="center" flexWrap="wrap">
        {neighborhoods?.map(neighborhood => (
          <Box
            display="flex"
            flexDirection="column"
            width={{ xs: '100%', sm: 'calc(100% / 3)', lg: '20%' }}
            // Disabling eslint rule as docs state to not use rule when
            // breaking array into chunks which is what we're doing here
            // eslint-disable-next-line react/no-array-index-key
            key={neighborhood.slug}
          >
            {neighborhoodComponent(neighborhood)}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

NeighborhoodList.propTypes = {
  neighborhoods: arrayOf(shape({})).isRequired,
};

export default NeighborhoodList;
