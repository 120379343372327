import React, { useRef } from 'react';
import { useRouter } from 'next/router';
import { node, string } from 'prop-types';
import { Paper, Box } from '@mui/material';
import { track } from '../../../../modules/segment';

const SalesforceForm = ({ formName, dateInput, children, ...rest }) => {
  const formRef = useRef();
  const { query: { clickid = '', gclid = '' } = {} } = useRouter() || {};

  const handleSubmit = event => {
    event.preventDefault();

    track(`Form Submitted - ${formName}`, {});

    if (dateInput) {
      formRef.current[dateInput].value = new Date(formRef.current[dateInput].value).toLocaleDateString('en-US');
    }

    formRef.current.submit();
  };
  return (
    <Paper>
      <Box
        ref={formRef}
        component="form"
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          gap: 2,
          background: theme => theme.palette.neutral.lightest,
          borderTop: theme => `${theme.spacing(1.25)} solid ${theme.palette.secondary.main}`,
          borderRadius: 1,
          padding: 6,
          // Hides text not wrapped in an HTML tag
          fontSize: 0,
          '& > div': theme => ({
            flex: '1 1 auto',
            width: '100%',
            [theme.breakpoints.up('md')]: {
              width: 'calc(50% - 16px)',
            },
          }),
        }}
        {...rest}
        onSubmit={handleSubmit}
      >
        {children}
        {gclid && <input type="hidden" name="Gclid__c" value={gclid} />}
        {clickid && <input type="hidden" name="Clickid__c" value={clickid} />}
      </Box>
    </Paper>
  );
};

SalesforceForm.propTypes = {
  formName: string.isRequired,
  dateInput: string.isRequired,
  children: node.isRequired,
};

export default SalesforceForm;
