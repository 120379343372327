import React, { createRef } from 'react';
import { bool, func, string } from 'prop-types';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import DialpadIcon from '@mui/icons-material/Dialpad';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import HearingIcon from '@mui/icons-material/Hearing';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import { FormControl, InputLabel } from '@mui/material';

const FormInput = ({ type, label, icon, name, required, onChange, defaultValue }) => {
  const ref = createRef();

  const adornmentIcons = {
    phone: LocalPhoneOutlinedIcon,
    person: PersonOutlineIcon,
    mail: MailOutlineIcon,
    destination: LocationOnOutlinedIcon,
    startLocation: CircleOutlinedIcon,
    calendar: CalendarTodayIcon,
    business: EmojiTransportationIcon,
    numberPad: DialpadIcon,
    hear: HearingIcon,
    size: AspectRatioIcon,
    truck: LocalShippingIcon,
  };

  const IconComponent = adornmentIcons[icon];

  return (
    <FormControl variant="outlined">
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <OutlinedInput
        inputRef={ref}
        label={label}
        type={type}
        name={name}
        required={required}
        defaultValue={defaultValue}
        onChange={e => onChange(e, name)}
        startAdornment={
          <InputAdornment position="start">
            {IconComponent ? <IconComponent color="secondary" fontSize="medium" /> : null}
          </InputAdornment>
        }
        sx={{
          width: '100%',
        }}
        inputProps={{
          style: { WebkitBoxShadow: '0 0 0 1000px white inset' },
        }}
      />
    </FormControl>
  );
};

FormInput.propTypes = {
  type: string.isRequired,
  label: string.isRequired,
  icon: string.isRequired,
  name: string.isRequired,
  required: bool.isRequired,
  defaultValue: string,
  onChange: func.isRequired,
};

FormInput.defaultProps = {
  defaultValue: '',
};

export default FormInput;
