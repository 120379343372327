import React from 'react';
import { shape } from 'prop-types';
import { Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useRouter } from 'next/router';
import Link from '../../Link';

const NestedCities = ({ stateData }) => {
  const { asPath } = useRouter();
  const pathArr = asPath.split('/');
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const tablet = useMediaQuery(theme.breakpoints.down('md'));

  const mobileCols = 1;
  const tabletCols = 3;
  const desktopCols = 5;

  const origNum = stateData.citiesV2.length;
  let citiesPerColumn = Math.ceil(origNum / desktopCols);
  const flooredCitiesPerColumn = Math.floor(origNum / desktopCols);
  const citiesPerColumnModulo = origNum % desktopCols;
  if (tablet) citiesPerColumn = Math.ceil(origNum / tabletCols);
  if (mobile) citiesPerColumn = origNum;

  let cityColumns = desktopCols;
  if (tablet) cityColumns = tabletCols;
  if (mobile) cityColumns = mobileCols;

  const cityComponent = city => (
    <Box width={{ xs: '100%', md: '85%' }} display="flex" align="center">
      <Link gutterBottom variant="body2" href={`/${city.state.slug}/${city.slug}/${pathArr[3]}`}>
        {city.city}
      </Link>
    </Box>
  );

  return (
    <Box display="flex" alignItems="center" flexDirection="column" width="100%">
      <Box width={{ xs: '100%', md: '85%' }} display="flex" align="center">
        {[...Array(cityColumns)].map((_, i) => (
          <Box
            display="flex"
            flexDirection="column"
            width={{ xs: '100%', sm: 'calc(100% / 3)', lg: '20%' }}
            // Disabling eslint rule as docs state to not use rule when
            // breaking array into chunks which is what we're doing here
            // eslint-disable-next-line react/no-array-index-key
            key={i}
          >
            {(stateData && i < citiesPerColumnModulo) || !citiesPerColumnModulo
              ? stateData?.citiesV2?.slice(i * citiesPerColumn, (i + 1) * citiesPerColumn).map(city => cityComponent(city))
              : stateData?.citiesV2
                  ?.slice((i + 1) * flooredCitiesPerColumn, (i + 2) * flooredCitiesPerColumn)
                  .map(city => cityComponent(city))}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

NestedCities.propTypes = {
  stateData: shape({}),
};

NestedCities.defaultProps = {
  stateData: null,
};

export default NestedCities;
