import React from 'react';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import { Box, Button, Typography } from '@mui/material';

const data = dynamic(() => import('../data'));

const Results = () => {
  const router = useRouter();
  const { market, size, property } = router.query;
  const prettyMarket = market.replace('_', ' ').toLowerCase().split(' ');
  const info = data[market]?.[property.toUpperCase()]?.[size];
  const options = [
    {
      title: 'Truck and Movers',
      details: 'Includes movers, a 26-foot truck, and a licensed and insured driver.',
      image: '/images/TruckMover.svg',
      price: info?.truckAndMovers,
      reccomended: true,
      link: '/book/location/?truck=1',
    },
    {
      title: 'Movers only',
      details: 'Includes only movers. Perfect for help unloading or loading.',
      image: '/images/LaborOnly.svg',
      price: info?.movers,
      reccomended: false,
      link: '/book/location/?truck=0',
    },
  ];
  return (
    <Box>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Typography
          mt={3}
          variant="body2"
          fontFamily="CalibreMedium"
          sx={{
            mt: 0,
          }}
        >
          {prettyMarket[0].charAt(0).toUpperCase().replaceAll('-', ' ') + prettyMarket[0].slice(1)},{' '}
          {prettyMarket[1].toUpperCase()}
        </Typography>
        <Typography
          sx={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            ml: 2,
            px: 2,
            mt: 0,
            '&:before, &:after': {
              content: "''",
              display: 'block',
              position: 'absolute',
              background: '#000',
              height: '1em',
              width: '2px',
            },
            '&:before': {
              right: 0,
            },
            '&:after': {
              left: 0,
            },
          }}
          mt={3}
          variant="body2"
          fontFamily="CalibreMedium"
          align="center"
        >
          {size}
        </Typography>
        <Button
          sx={{
            width: 20,
            padding: 0,
            '&:hover': { backgroundColor: 'transparent' },
          }}
          disableFocusRipple
          disableElevation
          onClick={() =>
            router.push({
              pathname: '/moving-costs-calculator',
              query: undefined,
            })
          }
        >
          Edit
        </Button>
      </Box>
      {options.map(({ title, details, image, price, reccomended, link }) => (
        <Box
          boxShadow={reccomended ? '0px 5px 10px #CDDBFB' : '0px 5px 15px rgba(116, 124, 130, 0.3)'}
          borderRadius={1}
          display="flex"
          width={{ xs: '100%', md: 800 }}
          height={{ xs: 160, md: 120 }}
          alignItems={{ xs: 'flex-start', md: 'center' }}
          justifyContent={{ md: 'space-around' }}
          flexWrap="wrap"
          my={3}
          p={{ xs: 3, md: 0 }}
        >
          <Box width={140} display={{ xs: 'none', md: 'flex' }} justifyContent="center" order={1}>
            <img src={image} alt={title} />
          </Box>
          <Box width={{ md: 260 }} order={{ xs: 3, md: 2 }} mt={{ xs: -2, md: 0 }}>
            <Typography variant="h4" sx={{ mb: { xs: 1, md: 0 } }}>
              {title}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {details}
            </Typography>
          </Box>
          <Box
            width={{ xs: '50%', md: 'auto' }}
            order={{ xs: 1, md: 3 }}
            display={{ xs: 'flex', md: 'block' }}
            textAlign={{ xs: 'left', md: 'right' }}
            alignItems="center"
          >
            <Typography variant="body2" color="textSecondary" align="right">
              Average
            </Typography>
            <Typography
              variant="h4"
              color="secondary"
              align="right"
              sx={{ ml: { xs: '2px', md: 0 }, fontSize: { xs: 18, md: 'inherit' } }}
            >
              ${price}
            </Typography>
          </Box>
          <Box
            order={{ xs: 2, md: 4 }}
            alignSelf={{ xs: 'flex-end', md: 'center' }}
            width={{ xs: '50%', md: 'auto' }}
            display={{ xs: 'flex', md: 'block' }}
            justifyContent="flex-end"
          >
            <Button sx={{ fontSize: 18, width: 120, height: 40, p: 0 }} variant="outlined" href={link}>
              Book now
            </Button>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default Results;
